import type { Stream, RequestOptions } from "@dxos/codec-protobuf";
import substitutions from "../../../substitutions.js";
import * as dxos_agent_dashboard from "../agent/dashboard.js";
import * as dxos_agent_echoproxy from "../agent/echoproxy.js";
import * as dxos_agent_epoch from "../agent/epoch.js";
import * as dxos_agent_functions from "../agent/functions.js";
import * as dxos_bot from "../bot.js";
import * as dxos_config from "../config.js";
import * as dxos_devtools_host from "../devtools/host.js";
import * as dxos_devtools_swarm from "../devtools/swarm.js";
import * as dxos_echo_blob from "../echo/blob.js";
import * as dxos_echo_feed from "../echo/feed.js";
import * as dxos_echo_filter from "../echo/filter.js";
import * as dxos_echo_indexing from "../echo/indexing.js";
import * as dxos_echo_metadata from "../echo/metadata.js";
import * as dxos_echo_model_document from "../echo/model/document.js";
import * as dxos_echo_model_messenger from "../echo/model/messenger.js";
import * as dxos_echo_model_text from "../echo/model/text.js";
import * as dxos_echo_object from "../echo/object.js";
import * as dxos_echo_query from "../echo/query.js";
import * as dxos_echo_service from "../echo/service.js";
import * as dxos_echo_snapshot from "../echo/snapshot.js";
import * as dxos_echo_timeframe from "../echo/timeframe.js";
import * as dxos_edge_messenger from "../edge/messenger.js";
import * as dxos_error from "../error.js";
import * as dxos_gravity from "../gravity.js";
import * as dxos_halo_credentials from "../halo/credentials.js";
import * as dxos_halo_credentials_auth from "../halo/credentials/auth.js";
import * as dxos_halo_credentials_greet from "../halo/credentials/greet.js";
import * as dxos_halo_credentials_identity from "../halo/credentials/identity.js";
import * as dxos_halo_invitations from "../halo/invitations.js";
import * as dxos_halo_keyring from "../halo/keyring.js";
import * as dxos_halo_keys from "../halo/keys.js";
import * as dxos_halo_signed from "../halo/signed.js";
import * as dxos_iframe from "../iframe.js";
import * as dxos_keys from "../keys.js";
import * as dxos_mesh_bridge from "../mesh/bridge.js";
import * as dxos_mesh_broadcast from "../mesh/broadcast.js";
import * as dxos_mesh_messaging from "../mesh/messaging.js";
import * as dxos_mesh_muxer from "../mesh/muxer.js";
import * as dxos_mesh_presence from "../mesh/presence.js";
import * as dxos_mesh_protocol from "../mesh/protocol.js";
import * as dxos_mesh_replicator from "../mesh/replicator.js";
import * as dxos_mesh_signal from "../mesh/signal.js";
import * as dxos_mesh_swarm from "../mesh/swarm.js";
import * as dxos_mesh_teleport from "../mesh/teleport.js";
import * as dxos_mesh_teleport_auth from "../mesh/teleport/auth.js";
import * as dxos_mesh_teleport_automerge from "../mesh/teleport/automerge.js";
import * as dxos_mesh_teleport_blobsync from "../mesh/teleport/blobsync.js";
import * as dxos_mesh_teleport_control from "../mesh/teleport/control.js";
import * as dxos_mesh_teleport_gossip from "../mesh/teleport/gossip.js";
import * as dxos_mesh_teleport_notarization from "../mesh/teleport/notarization.js";
import * as dxos_mesh_teleport_replicator from "../mesh/teleport/replicator.js";
import * as dxos_registry from "../registry.js";
import * as dxos_rpc from "../rpc.js";
import * as dxos_service_agentmanager from "../service/agentmanager.js";
import * as dxos_service_publisher from "../service/publisher.js";
import * as dxos_service_supervisor from "../service/supervisor.js";
import * as dxos_service_tunnel from "../service/tunnel.js";
import * as dxos_tracing from "../tracing.js";
import * as dxos_type from "../type.js";
import * as dxos_value from "../value.js";
import * as example_testing_data from "../../example/testing/data.js";
import * as example_testing_rpc from "../../example/testing/rpc.js";
import * as google_protobuf from "../../google/protobuf.js";
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/invitation.proto}
 */
export interface AdmissionKeypair {
    publicKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Options:
     *   - proto3_optional = true
     */
    privateKey?: ReturnType<(typeof substitutions)["dxos.keys.PrivateKey"]["decode"]>;
}
/**
 * Runtime state of the space object.
 *
 * Defined in:
 *   {@link file://./../../../dxos/client/invitation.proto}
 */
export enum SpaceState {
    INVALID = 0,
    SPACE_INACTIVE = 2,
    SPACE_ACTIVE = 6,
    SPACE_CLOSED = 1,
    SPACE_CONTROL_ONLY = 7,
    SPACE_INITIALIZING = 4,
    SPACE_READY = 3,
    SPACE_ERROR = 5,
    SPACE_REQUIRES_MIGRATION = 8
}
/**
 * Represents the invitation state passed between client and service.
 *
 * Defined in:
 *   {@link file://./../../../dxos/client/invitation.proto}
 */
export interface Invitation {
    /**
     * Local identifier (random).
     */
    invitationId: string;
    /**
     * Determines the behavior of the invitation.
     */
    type: Invitation.Type;
    /**
     * Kind of access the invitation will grant.
     */
    kind: Invitation.Kind;
    /**
     * How the invitation is authenticated.
     */
    authMethod: Invitation.AuthMethod;
    /**
     * Swarm rendezvous (random).
     */
    swarmKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Local state.
     */
    state: Invitation.State;
    /**
     * Timeout for guest to complete invitation once connected (ms).
     *
     * Options:
     *   - proto3_optional = true
     */
    timeout?: number;
    /**
     * Guest's identity.
     *
     * Options:
     *   - proto3_optional = true
     */
    identityKey?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Space to join (only present if kind is SPACE).
     *
     * Options:
     *   - proto3_optional = true
     */
    spaceKey?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Authentication code created by host (only present if auth_method is SHARED_SECRET).
     *
     * Options:
     *   - proto3_optional = true
     */
    authCode?: string;
    /**
     * Path or identifier to navigate to after successful authentication.
     *
     * Options:
     *   - proto3_optional = true
     */
    target?: string;
    /**
     * Host should resume invitation on startup until timeout.
     *
     * Options:
     *   - proto3_optional = true
     */
    persistent?: boolean;
    /**
     * Options:
     *   - proto3_optional = true
     */
    created?: ReturnType<(typeof substitutions)["google.protobuf.Timestamp"]["decode"]>;
    /**
     * Options:
     *   - proto3_optional = true
     */
    lifetime?: number;
    /**
     * Whether an invitation can be used multiple times.
     *
     * Options:
     *   - proto3_optional = true
     */
    multiUse?: boolean;
    /**
     * Guest's keypair required for AuthMethod.KNOWN_PUBLIC_KEY.
     *
     * Options:
     *   - proto3_optional = true
     */
    guestKeypair?: AdmissionKeypair;
    /**
     * Present on Type.DELEGATED invitations.
     *
     * Options:
     *   - proto3_optional = true
     */
    delegationCredentialId?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Role of the admitted member, defaults to ADMIN.
     *
     * Options:
     *   - proto3_optional = true
     */
    role?: dxos_halo_credentials.SpaceMember.Role;
    /**
     * Id of a the space to join (only present if kind is SPACE).
     *
     * Options:
     *   - proto3_optional = true
     */
    spaceId?: string;
}
export namespace Invitation {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/invitation.proto}
     */
    export enum Type {
        INTERACTIVE = 0,
        DELEGATED = 1,
        MULTIUSE = 2
    }
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/invitation.proto}
     */
    export enum Kind {
        DEVICE = 0,
        SPACE = 1
    }
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/invitation.proto}
     */
    export enum AuthMethod {
        NONE = 0,
        SHARED_SECRET = 1,
        KNOWN_PUBLIC_KEY = 2
    }
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/invitation.proto}
     */
    export enum State {
        INIT = 0,
        CONNECTING = 1,
        CONNECTED = 2,
        READY_FOR_AUTHENTICATION = 3,
        AUTHENTICATING = 4,
        SUCCESS = 5,
        CANCELLED = 6,
        TIMEOUT = 7,
        ERROR = 8,
        EXPIRED = 9
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export enum SystemStatus {
    INACTIVE = 0,
    ACTIVE = 1
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface UpdateStatusRequest {
    status: SystemStatus;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface QueryStatusRequest {
    /**
     * Options:
     *   - proto3_optional = true
     */
    interval?: number;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface QueryStatusResponse {
    status: SystemStatus;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface GetDiagnosticsRequest {
    /**
     * Options:
     *   - proto3_optional = true
     */
    keys?: GetDiagnosticsRequest.KEY_OPTION;
}
export namespace GetDiagnosticsRequest {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/services.proto}
     */
    export enum KEY_OPTION {
        NONE = 0,
        TRUNCATE = 1,
        HUMANIZE = 2
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface GetDiagnosticsResponse {
    timestamp: ReturnType<(typeof substitutions)["google.protobuf.Timestamp"]["decode"]>;
    diagnostics: ReturnType<(typeof substitutions)["google.protobuf.Struct"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface Platform {
    type: Platform.PLATFORM_TYPE;
    /**
     * The User-Agent string from a browser
     *
     * Options:
     *   - proto3_optional = true
     */
    userAgent?: string;
    /**
     * e.g process.platform from node, e.g. 'darwin', 'linux', 'win32'
     *
     * Options:
     *   - proto3_optional = true
     */
    platform?: string;
    /**
     * the Node.JS arch string, e.g. 'arm64', 'x64'
     *
     * Options:
     *   - proto3_optional = true
     */
    arch?: string;
    /**
     * the node.js version string
     *
     * Options:
     *   - proto3_optional = true
     */
    runtime?: string;
    /**
     * the number of seconds the client has been up
     *
     * Options:
     *   - proto3_optional = true
     */
    uptime?: number;
    /**
     * e.g. NodeJS.MemoryUsage, subject to change
     *
     * Options:
     *   - proto3_optional = true
     */
    memory?: ReturnType<(typeof substitutions)["google.protobuf.Struct"]["decode"]>;
}
export namespace Platform {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/services.proto}
     */
    export enum PLATFORM_TYPE {
        BROWSER = 0,
        SHARED_WORKER = 1,
        NODE = 2
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface SystemService {
    /**
     * Get the static config of the client.
     */
    getConfig: (request: void, options?: RequestOptions) => Promise<dxos_config.Config>;
    /**
     * Get the diagnostics snapshot.
     */
    getDiagnostics: (request: GetDiagnosticsRequest, options?: RequestOptions) => Promise<GetDiagnosticsResponse>;
    /**
     * Update the status of the client. Used to re-activate an inactive client.
     */
    updateStatus: (request: UpdateStatusRequest, options?: RequestOptions) => Promise<void>;
    /**
     * Stream the status of the client.
     */
    queryStatus: (request: QueryStatusRequest, options?: RequestOptions) => Stream<QueryStatusResponse>;
    /**
     * Reset the client.
     */
    reset: (request: void, options?: RequestOptions) => Promise<void>;
    /**
     * Get platform Information
     */
    getPlatform: (request: void, options?: RequestOptions) => Promise<Platform>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface Identity {
    identityKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Options:
     *   - proto3_optional = true
     */
    spaceKey?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Options:
     *   - proto3_optional = true
     */
    profile?: dxos_halo_credentials.ProfileDocument;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface RecoverIdentityRequest {
    seedphrase: string;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface CreateRecoveryPhraseResponse {
    seedphrase: string;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface QueryIdentityResponse {
    /**
     * Options:
     *   - proto3_optional = true
     */
    identity?: Identity;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface SignPresentationRequest {
    presentation: dxos_halo_credentials.Presentation;
    /**
     * Options:
     *   - proto3_optional = true
     */
    nonce?: Uint8Array;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface CreateIdentityRequest {
    /**
     * Options:
     *   - proto3_optional = true
     */
    profile?: dxos_halo_credentials.ProfileDocument;
    /**
     * Options:
     *   - proto3_optional = true
     */
    deviceProfile?: dxos_halo_credentials.DeviceProfileDocument;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface IdentityService {
    createIdentity: (request: CreateIdentityRequest, options?: RequestOptions) => Promise<Identity>;
    recoverIdentity: (request: RecoverIdentityRequest, options?: RequestOptions) => Promise<Identity>;
    createRecoveryPhrase: (request: void, options?: RequestOptions) => Promise<CreateRecoveryPhraseResponse>;
    queryIdentity: (request: void, options?: RequestOptions) => Stream<QueryIdentityResponse>;
    updateProfile: (request: dxos_halo_credentials.ProfileDocument, options?: RequestOptions) => Promise<Identity>;
    signPresentation: (request: SignPresentationRequest, options?: RequestOptions) => Promise<dxos_halo_credentials.Presentation>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export enum DeviceKind {
    CURRENT = 0,
    TRUSTED = 1
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface Device {
    deviceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    kind: DeviceKind;
    /**
     * Options:
     *   - proto3_optional = true
     */
    profile?: dxos_halo_credentials.DeviceProfileDocument;
    presence: Device.PresenceState;
}
export namespace Device {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/services.proto}
     */
    export enum PresenceState {
        OFFLINE = 0,
        ONLINE = 1,
        REMOVED = 2
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface QueryDevicesResponse {
    devices?: Device[];
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface DevicesService {
    updateDevice: (request: dxos_halo_credentials.DeviceProfileDocument, options?: RequestOptions) => Promise<Device>;
    queryDevices: (request: void, options?: RequestOptions) => Stream<QueryDevicesResponse>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface Contact {
    identityKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Options:
     *   - proto3_optional = true
     */
    profile?: dxos_halo_credentials.ProfileDocument;
    commonSpaces?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>[];
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface ContactBook {
    contacts?: Contact[];
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface ContactsService {
    getContacts: (request: void, options?: RequestOptions) => Promise<ContactBook>;
    queryContacts: (request: void, options?: RequestOptions) => Stream<ContactBook>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface SpaceMember {
    identity: Identity;
    presence: SpaceMember.PresenceState;
    peerStates?: dxos_mesh_presence.PeerState[];
    role: dxos_halo_credentials.SpaceMember.Role;
}
export namespace SpaceMember {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/services.proto}
     */
    export enum PresenceState {
        OFFLINE = 0,
        ONLINE = 1
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface Space {
    id: string;
    spaceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    state: SpaceState;
    /**
     * Options:
     *   - proto3_optional = true
     */
    pipeline?: Space.PipelineState;
    /**
     * Options:
     *   - proto3_optional = true
     */
    error?: dxos_error.Error;
    /**
     * Options:
     *   - proto3_optional = true
     */
    edgeReplication?: dxos_echo_metadata.EdgeReplicationSetting;
    members?: SpaceMember[];
    /**
     * Options:
     *   - proto3_optional = true
     */
    creator?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Options:
     *   - proto3_optional = true
     */
    cache?: dxos_echo_metadata.SpaceCache;
    metrics: Space.Metrics;
}
export namespace Space {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/services.proto}
     */
    export interface Metrics {
        /**
         * Options:
         *   - proto3_optional = true
         */
        open?: ReturnType<(typeof substitutions)["google.protobuf.Timestamp"]["decode"]>;
        /**
         * Options:
         *   - proto3_optional = true
         */
        pipelineInitBegin?: ReturnType<(typeof substitutions)["google.protobuf.Timestamp"]["decode"]>;
        /**
         * Options:
         *   - proto3_optional = true
         */
        controlPipelineReady?: ReturnType<(typeof substitutions)["google.protobuf.Timestamp"]["decode"]>;
        /**
         * Options:
         *   - proto3_optional = true
         */
        dataPipelineOpen?: ReturnType<(typeof substitutions)["google.protobuf.Timestamp"]["decode"]>;
        /**
         * Options:
         *   - proto3_optional = true
         */
        dataPipelineReady?: ReturnType<(typeof substitutions)["google.protobuf.Timestamp"]["decode"]>;
        /**
         * Options:
         *   - proto3_optional = true
         */
        ready?: ReturnType<(typeof substitutions)["google.protobuf.Timestamp"]["decode"]>;
    }
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/services.proto}
     */
    export interface PipelineState {
        /**
         * Options:
         *   - proto3_optional = true
         */
        controlFeedKey?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
        /**
         * Options:
         *   - proto3_optional = true
         */
        dataFeedKey?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
        /**
         * TODO(burdon): Normalize _key suffix.
         */
        controlFeeds?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>[];
        dataFeeds?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>[];
        /**
         * Last processed epoch. Might now have been applied yet.
         *
         * Options:
         *   - proto3_optional = true
         */
        currentEpoch?: dxos_halo_credentials.Credential;
        /**
         * Epoch that is currently applied.
         *
         * Options:
         *   - proto3_optional = true
         */
        appliedEpoch?: dxos_halo_credentials.Credential;
        /**
         * Mutations already processed.
         *
         * Options:
         *   - proto3_optional = true
         */
        currentControlTimeframe?: ReturnType<(typeof substitutions)["dxos.echo.timeframe.TimeframeVector"]["decode"]>;
        /**
         * Minimum timeframe to consider the state to be up-to-date.
         *
         * Options:
         *   - proto3_optional = true
         */
        targetControlTimeframe?: ReturnType<(typeof substitutions)["dxos.echo.timeframe.TimeframeVector"]["decode"]>;
        /**
         * All mutations stored in feeds.
         *
         * Options:
         *   - proto3_optional = true
         */
        totalControlTimeframe?: ReturnType<(typeof substitutions)["dxos.echo.timeframe.TimeframeVector"]["decode"]>;
        /**
         * All mutations known to exist on the network.
         *
         * Options:
         *   - proto3_optional = true
         */
        knownControlTimeframe?: ReturnType<(typeof substitutions)["dxos.echo.timeframe.TimeframeVector"]["decode"]>;
        /**
         * Start timeframe of the pipeline.
         *
         * Options:
         *   - proto3_optional = true
         */
        startDataTimeframe?: ReturnType<(typeof substitutions)["dxos.echo.timeframe.TimeframeVector"]["decode"]>;
        /**
         * Mutations already processed.
         *
         * Options:
         *   - proto3_optional = true
         */
        currentDataTimeframe?: ReturnType<(typeof substitutions)["dxos.echo.timeframe.TimeframeVector"]["decode"]>;
        /**
         * Minimum timeframe to consider the state to be up-to-date.
         *
         * Options:
         *   - proto3_optional = true
         */
        targetDataTimeframe?: ReturnType<(typeof substitutions)["dxos.echo.timeframe.TimeframeVector"]["decode"]>;
        /**
         * All mutations stored in feeds.
         *
         * Options:
         *   - proto3_optional = true
         */
        totalDataTimeframe?: ReturnType<(typeof substitutions)["dxos.echo.timeframe.TimeframeVector"]["decode"]>;
        /**
         * All mutations known to exist on the network.
         *
         * Options:
         *   - proto3_optional = true
         */
        knownDataTimeframe?: ReturnType<(typeof substitutions)["dxos.echo.timeframe.TimeframeVector"]["decode"]>;
        /**
         * URL of the current space root automerge document.
         *
         * Options:
         *   - proto3_optional = true
         */
        spaceRootUrl?: string;
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface UpdateSpaceRequest {
    spaceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Allowed values: ACTIVE, INACTIVE.
     *
     * Options:
     *   - proto3_optional = true
     */
    state?: SpaceState;
    /**
     * Options:
     *   - proto3_optional = true
     */
    edgeReplication?: dxos_echo_metadata.EdgeReplicationSetting;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface QuerySpacesResponse {
    spaces?: Space[];
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface PostMessageRequest {
    spaceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    channel: string;
    message: ReturnType<(typeof substitutions)["google.protobuf.Any"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface SubscribeMessagesRequest {
    spaceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    channel: string;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface WriteCredentialsRequest {
    spaceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    credentials?: dxos_halo_credentials.Credential[];
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface QueryCredentialsRequest {
    spaceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Options:
     *   - proto3_optional = true
     */
    noTail?: boolean;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface CreateEpochRequest {
    spaceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Options:
     *   - proto3_optional = true
     */
    migration?: CreateEpochRequest.Migration;
    /**
     * For REPLACE_AUTOMERGE_ROOT migration.
     *
     * Options:
     *   - proto3_optional = true
     */
    automergeRootUrl?: string;
}
export namespace CreateEpochRequest {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/services.proto}
     */
    export enum Migration {
        NONE = 0,
        INIT_AUTOMERGE = 1,
        PRUNE_AUTOMERGE_ROOT_HISTORY = 2,
        FRAGMENT_AUTOMERGE_ROOT = 3,
        REPLACE_AUTOMERGE_ROOT = 4,
        MIGRATE_REFERENCES_TO_DXN = 5
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface CreateEpochResponse {
    /**
     * Credential of the new epoch.
     *
     * Options:
     *   - proto3_optional = true
     */
    epochCredential?: dxos_halo_credentials.Credential;
    /**
     * Control pipeline timeframe.
     *
     * Options:
     *   - proto3_optional = true
     */
    controlTimeframe?: ReturnType<(typeof substitutions)["dxos.echo.timeframe.TimeframeVector"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface UpdateMemberRoleRequest {
    spaceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    memberKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    newRole: dxos_halo_credentials.SpaceMember.Role;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface AdmitContactRequest {
    contact: Contact;
    role: dxos_halo_credentials.SpaceMember.Role;
    spaceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface ContactAdmission {
    credential: dxos_halo_credentials.Credential;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface JoinSpaceResponse {
    space: Space;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface JoinBySpaceKeyRequest {
    spaceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface SpacesService {
    createSpace: (request: void, options?: RequestOptions) => Promise<Space>;
    updateSpace: (request: UpdateSpaceRequest, options?: RequestOptions) => Promise<void>;
    querySpaces: (request: void, options?: RequestOptions) => Stream<QuerySpacesResponse>;
    updateMemberRole: (request: UpdateMemberRoleRequest, options?: RequestOptions) => Promise<void>;
    admitContact: (request: AdmitContactRequest, options?: RequestOptions) => Promise<void>;
    joinBySpaceKey: (request: JoinBySpaceKeyRequest, options?: RequestOptions) => Promise<JoinSpaceResponse>;
    /**
     * Broadcast an ephemeral message to the space swarm.
     */
    postMessage: (request: PostMessageRequest, options?: RequestOptions) => Promise<void>;
    /**
     * Subscribe to messages from the space swarm.
     */
    subscribeMessages: (request: SubscribeMessagesRequest, options?: RequestOptions) => Stream<dxos_mesh_teleport_gossip.GossipMessage>;
    /**
     * Write credentials to the space control feed.
     */
    writeCredentials: (request: WriteCredentialsRequest, options?: RequestOptions) => Promise<void>;
    /**
     * Query credentials from the space control feed.
     */
    queryCredentials: (request: QueryCredentialsRequest, options?: RequestOptions) => Stream<dxos_halo_credentials.Credential>;
    createEpoch: (request: CreateEpochRequest, options?: RequestOptions) => Promise<CreateEpochResponse>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface AcceptInvitationRequest {
    invitation: Invitation;
    /**
     * Options:
     *   - proto3_optional = true
     */
    deviceProfile?: dxos_halo_credentials.DeviceProfileDocument;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface AuthenticationRequest {
    invitationId: string;
    authCode: string;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface CancelInvitationRequest {
    invitationId: string;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface InvitationMethod {
    created?: Invitation;
    accepted?: Invitation;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface QueryInvitationsResponse {
    action: QueryInvitationsResponse.Action;
    type: QueryInvitationsResponse.Type;
    invitations?: Invitation[];
    /**
     * Options:
     *   - proto3_optional = true
     */
    existing?: boolean;
}
export namespace QueryInvitationsResponse {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/services.proto}
     */
    export enum Action {
        ADDED = 0,
        REMOVED = 1,
        SAVED = 2,
        LOAD_COMPLETE = 3
    }
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/services.proto}
     */
    export enum Type {
        CREATED = 0,
        ACCEPTED = 1
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface LoadPersistentInvitationsResponse {
    invitations?: Invitation[];
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface InvitationsService {
    createInvitation: (request: Invitation, options?: RequestOptions) => Stream<Invitation>;
    acceptInvitation: (request: AcceptInvitationRequest, options?: RequestOptions) => Stream<Invitation>;
    authenticate: (request: AuthenticationRequest, options?: RequestOptions) => Promise<void>;
    cancelInvitation: (request: CancelInvitationRequest, options?: RequestOptions) => Promise<void>;
    queryInvitations: (request: void, options?: RequestOptions) => Stream<QueryInvitationsResponse>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export enum ConnectionState {
    OFFLINE = 0,
    ONLINE = 1
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface NetworkStatus {
    swarm: ConnectionState;
    signaling?: NetworkStatus.Signal[];
    connectionInfo?: dxos_devtools_swarm.SwarmInfo[];
}
export namespace NetworkStatus {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/services.proto}
     */
    export interface Signal {
        server: string;
        state: dxos_mesh_signal.SignalState;
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface UpdateConfigRequest {
    swarm: ConnectionState;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface NetworkService {
    updateConfig: (request: UpdateConfigRequest, options?: RequestOptions) => Promise<void>;
    queryStatus: (request: void, options?: RequestOptions) => Stream<NetworkStatus>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface EdgeAgentService {
    createAgent: (request: void, options?: RequestOptions) => Promise<void>;
    queryAgentStatus: (request: void, options?: RequestOptions) => Stream<QueryAgentStatusResponse>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/services.proto}
 */
export interface QueryAgentStatusResponse {
    status: QueryAgentStatusResponse.AgentStatus;
}
export namespace QueryAgentStatusResponse {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/services.proto}
     */
    export enum AgentStatus {
        UNKNOWN = 0,
        ACTIVE = 1,
        INACTIVE = 2,
        NOT_FOUND = 3
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/logging.proto}
 */
export interface Metrics {
    timestamp: ReturnType<(typeof substitutions)["google.protobuf.Timestamp"]["decode"]>;
    values?: Metrics.KeyPair[];
}
export namespace Metrics {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/logging.proto}
     */
    export interface KeyPair {
        /**
         * Options:
         *   - proto3_optional = true
         */
        key?: string;
        /**
         * Options:
         *   - proto3_optional = true
         */
        value?: dxos_value.Value;
        /**
         * Options:
         *   - proto3_optional = true
         */
        stats?: dxos_value.Stats;
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/logging.proto}
 */
export interface ControlMetricsRequest {
    /**
     * Options:
     *   - proto3_optional = true
     */
    reset?: boolean;
    /**
     * Options:
     *   - proto3_optional = true
     */
    record?: boolean;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/logging.proto}
 */
export interface ControlMetricsResponse {
    /**
     * Options:
     *   - proto3_optional = true
     */
    recording?: boolean;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/logging.proto}
 */
export interface QueryMetricsRequest {
    /**
     * Options:
     *   - proto3_optional = true
     */
    interval?: number;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/logging.proto}
 */
export interface QueryMetricsResponse {
    timestamp: ReturnType<(typeof substitutions)["google.protobuf.Timestamp"]["decode"]>;
    metrics: Metrics;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/logging.proto}
 */
export enum LogLevel {
    NONE = 0,
    TRACE = 5,
    DEBUG = 10,
    VERBOSE = 11,
    INFO = 12,
    WARN = 13,
    ERROR = 14
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/logging.proto}
 */
export interface QueryLogsRequest {
    filters?: QueryLogsRequest.Filter[];
    /**
     * Options:
     *   - proto3_optional = true
     */
    options?: QueryLogsRequest.MatchingOptions;
}
export namespace QueryLogsRequest {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/logging.proto}
     */
    export enum MatchingOptions {
        NONE = 0,
        INCLUSIVE = 1,
        EXPLICIT = 2
    }
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/logging.proto}
     */
    export interface Filter {
        level: LogLevel;
        /**
         * Options:
         *   - proto3_optional = true
         */
        pattern?: string;
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/logging.proto}
 */
export interface LogEntry {
    level: LogLevel;
    message: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    context?: ReturnType<(typeof substitutions)["google.protobuf.Struct"]["decode"]>;
    /**
     * Options:
     *   - proto3_optional = true
     */
    meta?: LogEntry.Meta;
    /**
     * Options:
     *   - proto3_optional = true
     */
    error?: dxos_error.Error;
    timestamp: ReturnType<(typeof substitutions)["google.protobuf.Timestamp"]["decode"]>;
}
export namespace LogEntry {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/client/logging.proto}
     */
    export interface Meta {
        file: string;
        line: number;
        /**
         * Options:
         *   - proto3_optional = true
         */
        scope?: ReturnType<(typeof substitutions)["google.protobuf.Struct"]["decode"]>;
        /**
         * Options:
         *   - proto3_optional = true
         */
        resourceId?: number;
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/client/logging.proto}
 */
export interface LoggingService {
    controlMetrics: (request: ControlMetricsRequest, options?: RequestOptions) => Promise<ControlMetricsResponse>;
    queryMetrics: (request: QueryMetricsRequest, options?: RequestOptions) => Stream<QueryMetricsResponse>;
    queryLogs: (request: QueryLogsRequest, options?: RequestOptions) => Stream<LogEntry>;
}
